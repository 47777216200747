import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyBvp74Gr0_HDy8Maa7xdp3EuxiogWHrCQs',
  authDomain: 'codex-narazareth.firebaseapp.com',
  projectId: 'codex-narazareth',
  storageBucket: 'codex-narazareth.appspot.com',
  messagingSenderId: '553948327273',
  appId: '1:553948327273:web:59705b9ac91eb0cf1d21f8',
  measurementId: 'G-40Q5WJ6W93',
};

firebase.initializeApp( config );

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const timeStamp = firebase.firestore.Timestamp;
export type TimeStamp = firebase.firestore.Timestamp;
export const fieldValue = firebase.firestore.FieldValue;
export type FieldValue = firebase.firestore.FieldValue;
export const authPersistenceLocal = firebase.auth.Auth.Persistence.LOCAL;
export const authPersistenceSession = firebase.auth.Auth.Persistence.SESSION;
export const emailAuthProvider = firebase.auth.EmailAuthProvider;
